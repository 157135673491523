/* --------------------Banner Section CSS Starts------------ */

.electric_cell_banner .service_banner_con {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/IEIM_electric_cell_banner_img.webp);
    background-position: right bottom -162px;

}

/* --------------------Banner Section CSS Ends------------ */

/* --------------------Banner Section Responsive Starts------------ */

@media only screen and (max-width: 1199px) {
.electric_cell_banner .service_banner_con {background-size: 100%;padding-top: 68%;}
}
/* --------------------Banner Section Responsive Ends------------ */


/* --------------------Block 1 CSS Starts------------ */

.electric_cell_block1 .left_image_and_text {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/IEIM_electric_cell_block1_img.webp);
}
.electric_cell_block1 .left_image_and_text .blue_text_background {background-size: 46% 100%;}
.electric_cell_block1 .left_image_and_text .text_position {padding-left: 878px;}

/* --------------------Block 1 CSS Ends------------ */

/* --------------------Block 1 Responsive Starts------------ */

@media only screen and (max-width: 1580px) {
    .electric_cell_block1 .left_image_and_text .text_position {padding-left: 58%;}
}

@media only screen and (max-width: 1480px) {
    .electric_cell_block1 .left_image_and_text {background-position: left -35% top;}
}

@media only screen and (max-width: 1306px) {
    .electric_cell_block1 .left_image_and_text {
        background-position: left -73% top;
    }
}

@media only screen and (max-width: 1251px) {
    .electric_cell_block1 .left_image_and_text {
        background-position: left -200% top;
    }
}

@media only screen and (max-width: 1199px) {
    .electric_cell_block1 .left_image_and_text {padding-top: 79% !important;}
}

/* --------------------Block 1 Responsive Ends------------ */


/* --------------------Block 2 CSS Starts------------ */

.electric_cell_block2 .textwrapper {width:58%;}
.electric_cell_block2 .image_wrapper {width:40%;}
.electric_cell_block2 {background: #dff5f9;}


/* --------------------Block 2 CSS Ends------------ */
/* --------------------Block 2 Responsive Starts------------ */

@media only screen and (max-width: 1199px) {
    .electric_cell_block2 .textwrapper {margin-top: 25px;}
}

/* --------------------Block 2 Responsive Ends------------ */

/* --------------------Block 3 CSS Starts------------ */

.electric_cell_block3 .left_image_and_text {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/IEIM_electric_cell_block3_img.webp);

}

.electric_cell_block3 .left_image_and_text .blue_text_background {background-size: 50% 100%;}
.electric_cell_block3 .left_image_and_text .text_position {padding-left: 807px;}
.electric_cell_block3 .textwrapper .colun_count2 {column-gap: 115px;}
.electric_cell_block3 .block_listwrapper p {display: inline-block;}

/* --------------------Block 3 CSS Ends------------ */

/* --------------------Block 3 Responsive Starts------------ */

@media only screen and (max-width: 1580px) {
    .electric_cell_block3 .left_image_and_text .text_position {padding-left: 54%;}
    .electric_cell_block3 .left_image_and_text {background-position: left -25% top;}
    .electric_cell_block3 .textwrapper .colun_count2 {
        column-gap: 126px;
    }
}


@media only screen and (max-width: 1400px) {
    .electric_cell_block3 .left_image_and_text {
        background-position: left -16% top;
    }
}

@media only screen and (max-width: 1377px) {
    .electric_cell_block3 .left_image_and_text .blue_text_background {
        background-size: 49% 100%;
    }
    .electric_cell_block3 .left_image_and_text .text_position {
        padding-left: 55%;
    }
}


@media only screen and (max-width: 1199px) {
    .electric_cell_block3 .left_image_and_text {padding-top: 40.8% !important;}
    .electric_cell_block3 .textwrapper .colun_count2 {column-gap: 0;}
    .electric_cell_block3 .block_listwrapper p {display: block;}
    .electric_cell_block3 .block_listwrapper p:nth-child(4){display: inline-block;}

}

@media only screen and (max-width: 599px) {
    .electric_cell_block3 .textwrapper .colun_count2 {
        column-gap: 125px;
    }
}




/* --------------------Block 3 Responsive Ends------------ */
