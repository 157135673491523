.contact_banner .banner_con{
    background-image:url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/contact_image1.png);
    padding: 63px 0 50px;
    background-size: auto 100%;
}
.contact_banner .banner_con_text1 h1{margin-bottom: 0px;}
.contact_banner .banner_con_text1 h5{font-size: 28px;font-weight: var(--font-medium);}


.contact_block2{background-color:#203560;padding:80px 0}
.contact_block2_colcontainer{background-color:#fff;padding:60px;box-shadow:0 0 10px rgba(0,0,0,.3);display:flex;justify-content:space-between;position:relative;padding-bottom:120px;}
.contact_block2_left{background-color:#203560;padding:35px;background-position:right bottom;background-repeat:no-repeat;width:29%}

.contact_block2_left .roundimage_imgmainwrapper{height: 310px;width: 310px;background: rgba(255,255,255,.2); margin: 0 auto; margin-bottom: 30px;}
.contact_block2_left .roundimage_imgsubwrapper{height: 260px;width: 260px;}
.contact_block2_left .roundimage_imgwrapper{height: 207px;width: 207px;}
.contact_block2_left .textwrapper h2{
    color: #fff;
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.contact_block2_left .textwrapper p{
    color: #fff;
    font-size: 25px;
    line-height: 32px;
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/location_icon.png);
    padding-left: 50px;
    background-position: left top;
    background-repeat: no-repeat;
}
.contact_block2_left .textwrapper h3 a{
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/call_icon2.png);
    color: #8dd83e;
    font-weight: var(--font-medium);
    font-size: 29px;
    background-repeat: no-repeat;
    padding: 5px 0;
    padding-left: 50px;
    background-position: left center;
    text-decoration: none;
}

.contact_block2_right {
    width: 63%;
}

.contact_block2_right .textwrapper h2{color: #203560;margin-bottom: 10px;text-transform: uppercase;}

.contactForm{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}
.col2 {
    width: 49%;
    padding: 0 0.5%;
    margin: 4px 0;
}

.contactForm .MuiFormControl-root{width: 100%;}
.contacttitle h1 {
    margin-bottom: 15px;
    margin-top: 25px;
    font-size: 27px;
    color: #000;
    font-weight: var(--font-semibold);
}
.contacttitle{
    display: block;
    width: 100%;
}

.singleCheckbox div.MuiFormControl-root{
    border: 1px solid #ddd;
    padding: 5px 3%;
    width: 94%;
}
.contactForm  .MuiInputBase-root{border-radius: 0;}

.col1 {
    width: 100%;
    padding: 0 0.5%;
    margin-top: 18px;
}

.contactForm textarea{
  height: 126px !important;
    border: 1px solid #ddd;
    resize: none;
    outline: none !important;
    padding: 15px;
    font-size: 16px;
}
 .contactForm .MuiOutlinedInput-notchedOutline{border: 1px solid #ddd !important;}
.description{position: relative;}
.description label{
    position: absolute;
    left: 14px;
    top: 9px;
    transition: all 0.2S;
}
.contactForm .description .Mui-focused{
    top: -9px;
    font-size: 13px;
    background: #fff;

}

.captcha {
   display: flex;
    align-items: center;
    padding: 12px;
    border: 2px solid #dddddf;
    border-radius: 0px;
    position: absolute;
    right: 340px;
    bottom: 45px;
}

.captcha label.MuiFormLabel-root{
    font-size: 20px;
    margin-right: 15px;
    font-weight: var(--font-bold);
    color: #000000;
}

.contactForm .captcha div.MuiFormControl-root{
    width: 62px;
}
.contactForm .captcha div.MuiFormControl-root input{
    padding: 2.5px 14px;
    background-color: #e7e7e7;
    text-align: center;
}

.contactForm .submitbtnsection .MuiButtonBase-root{
    background: rgb(29,188,231);
    background: linear-gradient(83deg, rgba(29,188,231,1) 0%, rgba(27,194,57,1) 100%);
    font-size: 20px;
    padding: 10px 15px;
    border-radius: 8px;
    color: #fff;
    text-transform: inherit;
    font-weight: var(--font-semibold);
    position: absolute;
    right: 60px;
    bottom: 45px;
}

.contactblock_imgmainwrapper{
background: rgb(72,161,69);
background: linear-gradient(0deg, rgba(72,161,69,1) 0%, rgba(132,196,65,1) 100%);
padding: 22px;
margin-bottom: 35px;
}
.contactblock_imgmainwrapper img{
   max-width: 100%;
    display: block;
    width: 100%;
}



@media only screen and (max-width: 1520px) {
.contact_block2_left .roundimage_imgmainwrapper{height:280px;width:280px;}
.contact_block2_left .roundimage_imgsubwrapper{height:240px;width:240px}
.contact_block2_left .roundimage_imgwrapper{height:187px;width:187px}
}


@media only screen and (max-width: 1400px) {
.contact_block2_right{width:63%}
.contact_block2_left{width: 27%;}
.contacttitle h1{margin: 20px 0;}
}


@media only screen and (max-width: 1280px) {
.contact_banner .banner_con{background-position: right -140px bottom;}
}
@media only screen and (max-width: 1240px) {
    .contactForm {
    margin-top: 20px;
}
.contact_block2_right {
    width: 60%;
}
.contact_block2_left {
    width: 30%;
}
}
@media only screen and (max-width: 1199px) {
.contact_block2_colcontainer{
    flex-wrap: wrap;background-position: left 166px bottom 20px;
    flex-direction: column-reverse;
}

.contact_block2_left {
    width: 92%;
    margin-top: 25px;
    padding: 4%;
}
.contact_block2_right{
      width: 100%;
    position: relative;
    padding-bottom: 80px;
}
.contact_block2_colcontainer{
    padding: 30px;
}
.contact_block2_left .textwrapper p br{display: none;}
.contactForm .submitbtnsection .MuiButtonBase-root{
    right: 0;
    bottom: 0px;
}
.captcha{bottom: 0px;right: 290px;}
.contact_block2 {
    padding: 30px 0;
}
.contact_banner .banner_con {
    background-position: right top;
    background-size: 108%;
    padding: 0;
    padding-top: 36%;
    padding-bottom: 2%;
    text-align: center;
}
.contact_banner .banner_con_text1 h1 {
    font-size: 42px;
    line-height: 45px;
    margin-bottom: 5px;
}
.contact_block2_left .textwrapper h2{font-size:36px;line-height:48px;}
}

@media only screen and (max-width: 991px) {
.contact_block2_colcontainer{background-position: left 30px  bottom 20px;}
}
@media only screen and (max-width: 710px) {
    .contact_block2_colcontainer{background: #fff;}

}
@media only screen and (max-width: 767px) {
.contact_banner .banner_con_text1 h1 {
    font-size: 32px;
    line-height: 41px;
}
}
@media only screen and (max-width: 540px) {
.captcha {
    right: inherit;
    position: inherit;
    bottom: inherit;
    margin-top: 15px;
    width: 100%;
}
.contact_block2_right {
    padding-bottom: 0;
}
.contactForm .captcha div.MuiFormControl-root {
    width: 100%;
}
.captcha label.MuiFormLabel-root {
    width: 27%;
}
.contactForm .submitbtnsection .MuiButtonBase-root {
    right: inherit;
    position: inherit;
    bottom: inherit;
    display: block;
        margin-top: 15px;
        width: 100%;
}
.contact_block2_colcontainer {
    padding: 20px;
}
.submitbtnsection {
    width: 100%;
}
.col2 {
    width: 100%;
    padding: 0;
    margin: 4px 0;
}
.col1 {
    width: 100%;
    padding: 0;
    margin-top: 8px;
}
}

@media only screen and (max-width: 480px) {
.contact_block2_left .roundimage_imgmainwrapper {
    height: 240px;
    width: 240px;
}
.contact_block2_left .roundimage_imgsubwrapper {
    height: 200px;
    width: 200px;
}
.contact_block2_left .roundimage_imgwrapper {
    height: 167px;
    width: 167px;
}
.contact_block2_left{padding: 15px;}
.contact_block2_left .textwrapper h3 a{padding-left: 40px;background-size: 28px;font-size: 20px;}
.captcha label.MuiFormLabel-root {
    width: 40%;
}
.contact_block2_left .textwrapper p { font-size: 18px;line-height: 24px;background-position: left top 3px;}
}

@media only screen and (max-width: 380px) {
.captcha{
    display: block;
}
.captcha label.MuiFormLabel-root {
    width: auto;
}
.contactForm .submitbtnsection .MuiButtonBase-root{font-size: 16px;}
}