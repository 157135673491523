.banner_meetourproviders {
  overflow: hidden;
}
.banner_meetourproviders img {
  display: block;
  width: 100%;
  max-width: 100%;
}

.meet_providers_block2 {
  background-image: url(https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/meetproviders_block1_bg_img.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 65px 0;
}

.meet_pro_subblock1 {
  position: relative;
  padding-left: 610px;
  margin-bottom: 10px;
}
.meet_pro_subblock1 .roundimage_imgmainwrapper {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 530px;
  width: 530px;
}
.meet_providers_block2 .roundimage_imgmainwrapper {
  background: rgb(161, 228, 213);
  background: linear-gradient(
    120deg,
    rgba(161, 228, 213, 1) 0%,
    rgba(165, 228, 238, 1) 100%
  );
}
.meet_providers_block2 .roundimage_imgsubwrapper {
  background: rgb(97, 212, 177);
  background: linear-gradient(
    120deg,
    rgba(97, 212, 177, 1) 0%,
    rgba(102, 210, 230, 1) 100%
  );
  height: 440px;
  width: 440px;
}

.meet_providers_block2 .roundimage_imgwrapper {
  height: 363px;
  width: 363px;
}
.meet_pro_txt_block .textwrapper h2 {
  font-size: 40px;
  line-height: 40px;
  color: darkblue;
}
.meet_pro_txt_block .textwrapper h3 {
  font-size: 30px;
  line-height: 35px;
  color: darkblue;
}
.meet_pro_txt_block .textwrapper p {margin-bottom: 10px;line-height: 24px;}
.meet_pro_txt_block .textwrapper {margin-bottom: 5px;}
.meet_providers_block2 .block_listwrapper p {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.meet_pro_txt_block .green_bullet_point p::after{top: 10px;}
.meet_pro_subblock1 img{display: block;Width:100%}

.meet_provider_block3 {background-image: url(https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/meet_pro_block3_bg_img.webp);background-repeat: no-repeat;background-position: center;background-size: cover;padding: 65px 0;}

.meet_pro_block3_sec1{position: relative;}

.meet_pro_block3_sec1 img{     display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 37%; }

.meet_pro_block3_txt h2{color: #fff; font-size: 40px; margin-bottom: 10px;}
.meet_pro_block3_txt h3{color:#fff;font-size: 25px; margin-bottom: 10px;}
.meet_pro_block3_txt .textwrapper p{color: #fff; 
    padding-right: 566px}
.meet_pro_block3_txt .textwrapper p span{color: #fff600;}
.txt_sec h5 {font-size: 23px;line-height: 28px;color:#fff600;margin-bottom: 10px;font-weight: 400;}
.txt_sec p { font-size: 18px; color:#fff;}

.meet_pro_block3_innr2 {margin-top: 25px;}

@media only screen and (max-width:1699px){
    .meet_pro_block3_txt .textwrapper p:nth-last-child(1){
        padding-right: 0;
    }
}

@media only screen and (max-width:1490px){
    .meet_pro_block3_txt .textwrapper p:nth-last-child(2){
        padding-right: 0;
    }
    .meet_provider_block3{
        padding: 30px 0;
    }
    .meet_providers_block2{
        padding: 30px 0;
    }
}

@media only screen and (max-width:1320px){
    .meet_pro_txt_block .textwrapper h2{
        font-size: 35px;
        margin-bottom: 15px;
    }

}

@media only screen and (max-width:1290px){
    .meet_pro_block3_txt .textwrapper p:nth-last-child(3){
        padding-right: 0;
    }
}

@media only screen and (max-width:1199px){
    .meet_pro_block3_txt .textwrapper p{
        padding-right: inherit;
    }

    .meet_pro_block3_sec1 img{    
         display: block;
        position: relative;
        right: 0;
        top: 0;
        width: 100%;
       margin-bottom: 10px; }
       .meet_provider_block3{
        padding: 0 0 15px;
       }

       .meet_pro_subblock1 .roundimage_imgmainwrapper{
        position: relative;
        left: 0;
        top: inherit;
        transform: inherit;
        margin: 0 auto 10px;
       }
       .meet_pro_subblock1{
        padding-left: inherit;
       }
}

@media only screen and (max-width:767px){
    .meet_pro_txt_block .textwrapper h2 {
        font-size: 32px;
        margin-bottom: 5px;
    }
    .meet_providers_block2 {
        padding: 15px 0;
    }
    .meet_pro_block3_txt h2 {
        
        font-size: 32px;
        margin-bottom: 0;
        
    }
    .meet_pro_txt_block .textwrapper h3{
        font-size: 26px;
        line-height: 31px;
    }

    .meet_pro_block3_txt .textwrapper p{
        margin-bottom: 15px;
    }
    .meet_pro_block3_txt h3{
        margin-bottom: 5px;
    }
    .meet_pro_block3_innr2 {
        margin-top: 15px;
    }
}


@media only screen and (max-width:560px){

    .meet_pro_subblock1 .roundimage_imgmainwrapper{
        height: 290px;
        width: 290px;
    }

    .meet_providers_block2 .roundimage_imgsubwrapper{
        
    height: 240px;
    width: 240px;
    }

    .meet_providers_block2 .roundimage_imgwrapper {
        height: 190px;
        width: 190px;
    }
    .txt_sec1 p:last-child {
        margin-bottom: 0;
    }
}