.aboutus_banner .banner_con {   
     background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/aboutus_banner_img.webp);
     background-position: right 55px bottom -50px;
}
.aboutus_block1 .left_image_and_text { background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/aboutus_block1_img.webp);}

.aboutus_block2{background: #dff5f9;padding-bottom: 50px;overflow: hidden;}
.aboutus_block2 h1{font-size: 42px;font-weight: 700;line-height: 52px; color: #0a1e72;padding-top: 50px;}
.aboutus_block2 h2{ font-size: 30px;font-weight: 700;line-height: 25px; color: #000;margin-bottom: 25px;margin-top: 30px;}
.aboutus_block2_sub_block{background-color: #c6e4ea;padding: 25px;border: 1px solid #fff;}
.aboutus_block2_sub_block_min{display: flex;justify-content: space-between;margin-bottom: 13px;}
.aboutus_block2_sub_block_min:last-child{margin-bottom: 0;}
.b2_min_sub_left span{font-weight: 700;color: #0a1e72;}
.b2_min_sub_left h3{font-size: 25px;font-weight: 400;color: #000;}
.b2_min_sub_left p{font-size: 25px;font-weight: 400;color: #000;}

.b2_min_sub_right h4{background-color: #dff5f9;padding: 10px;width: 270px;}
.b2_min_sub_right h4{font-size: 25px;font-weight: 700;color: #000;text-align: center;}
.align1{display: flex;align-items: start;}
.align2{display: flex;align-items: center;}
.align3{display: flex;align-items: end;}

.aboutus_block3{background: #dff5f9;padding-bottom: 50px;}
.aboutus_block3_sub_block{background-color: #1f355f;padding: 40px;display: flex;justify-content: space-between;}
.aboutus_block3_sub_block_left{width: 61%;}
.aboutus_block3_sub_block_left h1{font-size: 42px;font-weight: 700;line-height: 52px; color: #fff;margin-bottom: 13px;}
.aboutus_block3_sub_block_left h1:nth-of-type(2){margin-bottom: 0;}
.aboutus_block3_sub_block_left ul li{position: relative; list-style: none;font-size: 16px;font-weight: 400;line-height: 24px;margin-bottom: 11px; color: #fff;}
.aboutus_block3_sub_block_left ul li:last-child{margin-bottom: 0;}
.aboutus_block3_sub_block_left ul li::after{content: ''; width: 14px;height: 14px;position: absolute;background-color: #5ba60d;border: 1px solid #fff;left: -34px;top: 50px;transform: translateY(-46px);}
.aboutus_block3_sub_block_left ul{padding-left: 35px;}
.aboutus_block3_sub_block_left ul:nth-of-type(1){margin-bottom: 34px;}
/* .aboutus_block3_sub_block_left ul:nth-of-type(1) li{display: inline-block;margin-bottom: 11px;} */
.aboutus_block3_sub_block_right{width: 36%;}
.aboutus_block3_sub_block_right img{width: 100%;display: block;height: 100%;object-fit: cover;}


.aboutus_block4{background: #dff5f9;padding-bottom: 50px;}
.aboutus_block4_sub_block{background-color: #c6e4ea;padding:0 40px;border: 1px solid #fff;}
.block4_sub_block_min{display: block;justify-content: space-between;border-bottom: 1px solid #fff;padding-bottom: 8px;padding-top: 22px;}
.block4_sub_block_min h1{font-size: 42px;font-weight: 600;color: #1f355f;}
.block4_sub_block_min h2{font-size: 25px;font-weight: 700;}
.block4_wrpr ul li{position: relative; list-style: none;font-size: 16px;font-weight: 400;line-height: 24px;margin-bottom: 11px; color: #000;}
.block4_wrpr ul li:last-child{margin-bottom: 0;}
.block4_wrpr ul li::after{content: ''; width: 14px;height: 14px;position: absolute;background-color: #5ba60d;border: 1px solid #fff;left: -34px;top: 50px;transform: translateY(-46px);}
.block4_wrpr ul{padding-left: 34px;}
.b4_sbm_right h3{font-size: 25px;font-weight: 700;}
.block4_sub_block_min h6{font-size: 16px;font-weight: 700;color: #1f355f;}
.b4_sbm_left {width: 68%;}
.b4_sbm_right {width: 360px;display: flex;align-items: center;justify-content: flex-end;}
.block4_sub_block_min:nth-of-type(6){display: block;}
.block4_sub_block_min:nth-of-type(5){display: block;}
.block4_sub_block_min:last-child{border-bottom: none;}
.main_black ul li {display: flex; justify-content: space-between;}
.black_line1 {height: 1px;width: 70%;background-color: #90b1b7;margin: 11px;}
.black_line2 {height: 1px;width: 69%;background-color: #90b1b7;margin: 11px;}
.black_line3 {height: 1px;width: 70%;background-color: #90b1b7;margin: 11px;}
.black_line4 {height: 1px;width: 69%;background-color: #90b1b7;margin: 11px;}
.black_line5 {height: 1px;width: 75%;background-color: #90b1b7;margin: 11px;}
.black_line6 {height: 1px;width: 75%;background-color: #90b1b7;margin: 11px;}
.black_line7 {height: 1px;width: 77%;background-color: #90b1b7;margin: 11px;}

@media only screen and (max-width: 1640px) {
.aboutus_block1 .left_image_and_text{background-position: left -18% top;}

}
@media only screen and (max-width: 1541px){
     .black_line2{width: 67%;}
}
@media only screen and (max-width: 1530px){
     .black_line4{width: 66%;}
}
@media only screen and (max-width: 1530px){
     .black_line4{width: 66%;}
     .black_line7{width: 74%;}
     .black_line6{width: 73%;}
     .black_line5{width: 73%;}
     .black_line3{width: 68%;}
     .black_line2{width: 66%;}
     .black_line1{width: 67%;}
}
@media only screen and (max-width: 1420px){
     .black_line4{width: 64%;}
     .black_line7{width: 72%;}
     .black_line6{width: 71%;}
     .black_line5{width: 71%;}
     .black_line3{width: 66%;}
     .black_line2{width: 64%;}
     .black_line1{width: 65%;}
}
@media only screen and (max-width: 1420px){
     .black_line4{width: 61%;}
     .black_line7{width: 69%;}
     .black_line6{width: 68%;}
     .black_line5{width: 68%;}
     .black_line3{width: 63%;}
     .black_line2{width: 61%;}
     .black_line1{width: 62%;}
}
@media only screen and (max-width: 1250px){
     .black_line4{width: 55%;}
     .black_line7{width: 65%;}
     .black_line6{width: 64%;}
     .black_line5{width: 64%;}
     .black_line3{width: 59%;}
     .black_line2{width: 55%;}
     .black_line1{width: 58%;}
}
@media only screen and (max-width: 1498.98px) {
.aboutus_banner .banner_con {background-position: right -50px bottom -50px;}
.aboutus_block1 .left_image_and_text {background-position: left -37% top;}

}
@media only screen and (max-width: 1380px) {
/* .aboutus_block1 .left_image_and_text {background-position: left -58% top;} */
}
@media only screen and (max-width: 1298.98px) {
.aboutus_block1 .left_image_and_text {background-position: left -300px top;}
}

@media only screen and (max-width: 1212.98px){
     .b2_min_sub_right h4{font-size: 22px;width: 240px;}
     .b2_min_sub_left h3{font-size: 23px;}
     .b2_min_sub_left p{font-size: 23px;}
}

@media only screen and (max-width: 1199px) {
.aboutus_banner .banner_con {background-position: center top;background-size: 100%;padding-top: 36%;}
.aboutus_block2 h1 {padding-top: 30px;}
.black_line4{width: 45%;}
     .black_line7{width: 55%;}
     .black_line6{width: 54%;}
     .black_line5{width: 54%;}
     .black_line3{width: 49%;}
     .black_line2{width: 45%;}
     .black_line1{width: 48%;}
     .aboutus_block3_sub_block_right{display: none;}
     .aboutus_block3_sub_block_left{width: auto;}
     .block4_sub_block_min{flex-direction: column;}
     .b4_sbm_left{width: auto;}
     .b4_sbm_right{width: auto;}
}
@media only screen and (max-width: 1135.98px){
     .b2_min_sub_left h3{font-size: 20px;}
     .b2_min_sub_left p{font-size: 20px;}
     .b2_min_sub_right h4 {font-size: 20px;width: 181px;}
}
@media only screen and (max-width: 990px){
     .black_line4{width: 35%;}
     .black_line7{width: 45%;}
     .black_line6{width: 44%;}
     .black_line5{width: 44%;}
     .black_line3{width: 39%;}
     .black_line2{width: 35%;}
     .black_line1{width: 38%;}
     .aboutus_block2_sub_block_min{flex-direction: column;}
     .b2_min_sub_left h3{font-size: 25px;}
     .b2_min_sub_left p{font-size: 25px;margin-bottom: 7px;}
     .b2_min_sub_left span{display: block;}
}
@media only screen and (max-width: 850px){
     .black_line4{width: 25%;}
     .black_line7{width: 40%;}
     .black_line6{width: 40%;}
     .black_line5{width: 40%;}
     .black_line3{width: 29%;}
     .black_line2{width: 25%;}
     .black_line1{width: 28%;}
}
@media only screen and (max-width: 760px){
     .black_line4{width: 15%;}
     .black_line7{width: 30%;}
     .black_line6{width: 30%;}
     .black_line5{width: 30%;}
     .black_line3{width: 19%;}
     .black_line2{width: 15%;}
     .black_line1{width: 18%;}
     .aboutus_block3_sub_block_left h1{font-size: 35px;line-height: 44px;}
}
@media only screen and (max-width: 685px){
     .black_line4{display: none;}
     .black_line7{display: none;}
     .black_line6{display: none;}
     .black_line5{display: none;}
     .black_line3{display: none;}
     .black_line2{display: none;}
     .black_line1{display: none;}
     .main_black ul li{flex-direction: column;}
     .aboutus_block2 h1{font-size: 37px;    line-height: 35px;}
     .aboutus_block2 h2{margin-top: 10px;}
     .aboutus_block3_sub_block_left h1{font-size: 30px;line-height: 40px;}

}
@media only screen and (max-width: 610px){
     .b2_min_sub_left h3{font-size: 20px;}
     .b2_min_sub_left p{font-size: 20px;}
     .b2_min_sub_right h4{font-size: 18px;}
     .aboutus_block2 h1{font-size: 33px;}
     .block4_sub_block_min h1{font-size: 35px;}
     .block4_sub_block_min h2{line-height: 33px;}
     .aboutus_block4_sub_block{padding: 0 20px;}
     .block4_sub_block_min:first-child{padding-top: 10px;}
     .aboutus_block3_sub_block{padding: 20px;}
     .block4_sub_block_min{padding-top: 19px;}
     .aboutus_block2 h1{padding-top: 30px;}
     .aboutus_block2{padding-bottom: 30px;}
     .aboutus_block3{padding-bottom: 30px;}
     .aboutus_block4{padding-bottom: 30px;}
}
@media only screen and (max-width: 498.98px) {
.aboutus_banner .banner_con {background-size: 126%;padding-top: 45%;}
.aboutus_block2 h1{font-size: 30px;}
.aboutus_block3_sub_block_left h1{font-size: 27px;line-height: 38px;}
.b4_sbm_right h3{font-size: 19px;}
.block4_sub_block_min h2{font-size: 23px;}
.block4_sub_block_min h1 {font-size: 29px;margin-bottom: 5px;}
}
@media only screen and (max-width: 425.98px) {
     .b4_sbm_right{justify-content: center;}
     .block4_sub_block_min h2{font-size: 19px;line-height: 26px;}
     .aboutus_block3_sub_block_left h1 {font-size: 25px;line-height: 32px;}
}

