.image_gall_banner .service_banner_con {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/img_bnnr.webp);
    background-position: right bottom 0px;
    background-size: auto 100%;
} 

@media(max-width:1480px){
    .image_gall_banner .service_banner_con {
       background-position: right -120px bottom 0px;
        }
}

@media(max-width:1199px){
    .image_gall_banner .service_banner_con {
        background-size: 120%;
        padding-top: 35.5%;
        
    }
}
