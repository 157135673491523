/* rajdeep__------------advnc_bio1-------------------------------------------------------------- */

.Advanced_bio_bnnr .service_banner_con {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/advnced_bio_bnnr.png);
    background-position: right bottom ;
    padding: 80px 0 60px 0;
}

.Advanced_bio_bnnr .font55 h1 {margin-bottom: 10px;}

.AdvancedBiologics_wrpr1 .left_image_and_text {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/advnced_bio_img1.png);
    background-position: left bottom;
    background-size: auto 140%;
}
.AdvancedBiologics_wrpr1 .left_image_and_text .text_position {
    padding-left: 805px;
    padding-right: 108px;
}

.AdvancedBiologics_wrpr2 .textwrapper {width: 52%;}
.AdvancedBiologics_wrpr2 .block_listwrapper p {font-weight: 400;}
.AdvancedBiologics_wrpr2 .image_wrapper {width: 41%;}
.AdvancedBiologics_wrpr2{background-color: #cce7ec;}
.AdvancedBiologics_wrpr2 .img_innr_inn {
    height: 600px;
}
.textwrapper h4 {font-size: 36px; font-weight: 700; line-height: 42px; margin-bottom: 15px; color: #fff;}
.textwrapper h5 {font-size: 25px; font-weight: 500; line-height: 31px; margin-bottom: 15px; color: #fff;}

.AdvancedBiologics_wrpr3 .font_color{color: #46e965 !important;font-weight: 500;}
.AdvancedBiologics_wrpr3 .gred_color{background: rgb(0,200,254);
    background: linear-gradient(90deg, rgba(0,200,254,1) 0%, rgba(27,194,58,1) 100%); margin-bottom: 20px; width: fit-content; padding: 10px 35px;}
.AdvancedBiologics_wrpr3 .gred_color h3{margin-bottom: 0 !important;}    
.AdvancedBiologics_wrpr3 .left_image_and_text{background-image: url(https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/advnced_bio_img5.webp); background-size: auto 100%; }  
.AdvancedBiologics_wrpr3 .white-title-white-description h3 {
    color: #fff;margin-bottom: 20px;}
.AdvancedBiologics_wrpr3 .gred_color a {text-decoration: none;}
.AdvancedBiologics_wrpr4 .font_black {color: #000 !important; margin-bottom: 15px;}
.AdvancedBiologics_wrpr4{background-color: #cce7ec;}
.AdvancedBiologics_wrpr4 .image_wrapper {width: 41%;}
.AdvancedBiologics_wrpr4 .textwrapper {width: 54%;}
.AdvancedBiologics_wrpr4 .block_listwrapper {column-count: 2;}
.AdvancedBiologics_wrpr4 .block_listwrapper  p{font-weight: 400;}
.AdvancedBiologics_wrpr4 .img_height {height: 240px; overflow: hidden;box-shadow: 0 0 10px #8babb5;}
.AdvancedBiologics_wrpr4 .textwrapper h2 {margin-bottom: 15px;}
.AdvancedBiologics_wrpr4 .textwrapper p {margin-bottom: 15px;}
.AdvancedBiologics_wrpr4 .image_inner_wrapper {padding: 23px;}
.AdvancedBiologics_wrpr5{position: relative;}

.AdvancedBiologics_wrpr5 .derma_adv_abs_img {
    position: absolute;
    right: 50%;
    bottom: 0;
    width: 250px;
}
.font_white{color: #fff;}
.AdvancedBiologics_wrpr5 .left_image_and_text{background-image: url(https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/advnc_block5_bg_img.png);background-size: auto 102%;}
.AdvancedBiologics_wrpr5 .block_listwrapper ul{list-style-type: none; margin: 0;}
.AdvancedBiologics_wrpr5 .block_listwrapper ul li{position: relative;padding: 5px 0 5px 20px; font-size: 16px; line-height: 22px;color: #fff;}
.AdvancedBiologics_wrpr5 .block_listwrapper ul li::before{content: " "; background-color: #00c7fb; width: 14px; height: 14px; position: absolute; left: 0; top: 9px; border-radius: 50%;}
.AdvancedBiologics_wrpr5 .coloumn_flex {display: flex; justify-content: space-between; align-items: flex-start;}
.AdvancedBiologics_wrpr5 .coloumn_flex .block_listwrapper {width: 48%;}
.AdvancedBiologics_wrpr5 .block_listwrapper p {color: #fff;font-size: 16px; font-weight: 500;}
.AdvancedBiologics_wrpr5 .textwrapper h2 {margin-bottom: 20px;}
.AdvancedBiologics_wrpr5 .colun_count2 {margin-bottom: 15px;}
@media(max-width:1580px){
    .AdvancedBiologics_wrpr1 .left_image_and_text .text_position{ padding-left: 54%; padding-right: 0;}
}

@media(max-width:1500px){
    .Advanced_bio_bnnr .service_banner_con{
        background-position: right -145px bottom;
    }
    .healthrisk_block1 .left_image_and_text{background-position: left -112px top;}
   
}
@media only screen and (max-width: 1199px){
   
    .Advanced_bio_bnnr .service_banner_con {
        background-size: 134%;
        padding-top: 33%;
        padding-bottom: 25px;
    }
    .AdvancedBiologics_wrpr1 .left_image_and_text {
        padding-top: 63% !important;
       
        background-size: 100%;
    }
    .AdvancedBiologics_wrpr2 .img_innr_inn {
        height: auto;
    }
    .AdvancedBiologics_wrpr3 .left_image_and_text{
        background-size: 100%;
        padding-top: 52% !important;
    }
    .AdvancedBiologics_wrpr4 .img_height{
        height: auto;
    }
    .AdvancedBiologics_wrpr5 .derma_adv_abs_img {
       
        right: 0;
        top: 0;
        width: 20%;
    }
    .AdvancedBiologics_wrpr5 .left_image_and_text{
        background-size: 100%; padding-top: 66% !important;
    }
    .AdvancedBiologics_wrpr4 .image_inner_wrapper{padding: 15px;}
    .AdvancedBiologics_wrpr5 .textwrapper h2 {
        margin-bottom: 10px;
    }
}
@media only screen and (max-width: 560px){
    .AdvancedBiologics_wrpr4 .block_listwrapper {
        column-count: 1;
    }
    .AdvancedBiologics_wrpr5 .coloumn_flex{
        flex-direction: column;
    }
    .AdvancedBiologics_wrpr5 .coloumn_flex .block_listwrapper{
        width: 100%;
    }
    .Advanced_bio_bnnr .service_banner_con {
        background-size: 175%;
        padding-top: 45%;
        
    }
    .AdvancedBiologics_wrpr3 .gred_color{padding: 10px 20px;}
}


/* rajdeep__------------advnc_bio1-------------------------------------------------------------- */




/* soubhik~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~advance_bio2~~~~start~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
.SD_block7 .left_image_and_text {
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/IEIM_Advance_bio_block7_img1.png);
    background-position: left -629px bottom -232px;
    background-size: auto 127%;
}
.SD_block7 span{color: #46e965;}
.SD_block7 .advance_img_abs{position: absolute;right: 50%;width: 20%;}
.SD_block7{position: relative;}
.SD_block6 .bold_span_b6{font-weight: 600;}
.SD_block6 .sky_text_background{    background-size: 49.7% 100% !important;}
.SD_block6 .text_position{padding-right: 54% !important;}
.SD_block6 .right_image_and_text {background-image: url(https://all-frontend-assets.s3.amazonaws.com/IEIM/assets/IEIM_Advance_bio_block6_img1.png) ;background-position: right -123px bottom -105px;background-size: auto 124%;}




@media only screen and (max-width: 1799.98px){
.SD_block7 .left_image_and_text{background-position: left -689px bottom -232px;}
}
@media only screen and (max-width: 1664.98px){
    .SD_block7 .left_image_and_text{background-position:left -648px bottom -92px;background-size: auto 112%;}
    .SD_block6 .right_image_and_text{background-position: right -260px bottom -105px;}
    }
@media only screen and (max-width: 1424.98px){
.SD_block7 .left_image_and_text{background-position:left -657px bottom 0px;background-size: auto 103%;}
    .SD_block6 .right_image_and_text{background-position: right -208px bottom 0px;background-size: auto 100%;}
}
@media only screen and (max-width: 1199Px){
.SD_block7 .left_image_and_text{background-position:left 95% top !important;background-size: auto 57%;}
.SD_block7 .advance_img_abs{right: auto; left: 0;top: 0;width: 30%;}
.SD_block6 .right_image_and_text{background-size: auto;background-position: top right -126px !important;}
.SD_block6 .sky_text_background{background-size: 100% 100% !important;}
.SD_block6 .text_position{padding-right: 0 !important;}
}
@media only screen and (max-width: 950Px){
.SD_block7 .left_image_and_text{background-position:left 95% top !important;background-size: auto 48%;}
.SD_block6 .right_image_and_text{background-size: 123%;}    
}
@media only screen and (max-width: 760Px){
    .SD_block7 .left_image_and_text{background-position:left 70% top !important;background-size: auto 40%;}
    .SD_block6 .right_image_and_text{background-position: top -45px right -126px !important;background-size: 138%;}
    }
@media only screen and (max-width: 484Px){
.SD_block7 .left_image_and_text{background-position:left 70% top -32px !important;background-size: auto 25%;}
.SD_block6 .right_image_and_text{background-position: top -29px right -71px !important;background-size: 135%;}
}   
@media only screen and (max-width: 380Px){
    .SD_block7 .left_image_and_text{background-position:left 70% top -17px !important;background-size: auto 18%;}
    }   
/* soubhik~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~advance_bio2~~~~end~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
