.counseling_banner .service_banner_con {    background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/services_banner_img.webp);
 
    background-position: right 125px bottom -60px;
    background-repeat: no-repeat;
    background-size: auto;}

.counseling_block1 .left_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/counseling_block1_img.webp);}
.counseling_block1 .left_image_and_text .text_position {padding-left: 835px;}
.counseling_block1 .left_image_and_text .blue_text_background {background-size: 48% 100%;}
.counseling_block2 {background: #dff5f9;}
.counseling_block2 .textwrapper {width: 49%;}
/* .counseling_block2 .block_listwrapper {padding-right: 210px;} */
/* .counseling_block2 .block_listwrapper p {white-space: nowrap;} */

@media only screen and (max-width: 1799px) {
.counseling_block1 .left_image_and_text {background-position: left -30% top;}

}

@media only screen and (max-width: 1580px) {
.counseling_banner .service_banner_con {background-position: right 5px bottom -60px;}
.counseling_block1 .left_image_and_text .text_position {padding-left: 55%;}
.counseling_block1 .left_image_and_text {background-position: left -90% top;}
}
@media only screen and (max-width: 1498.98px){
.counseling_block1 .left_image_and_text {background-position: left -380px top;}
}
@media only screen and (max-width: 1398.98px) {
.counseling_banner .service_banner_con {background-position: right -45px bottom -60px;}
/* .counseling_block2 .block_listwrapper {padding-right: 150px;} */
}
@media only screen and (max-width: 1280px) {
.counseling_block2 .block_listwrapper p {    white-space: pre-wrap;}
/* .counseling_block2 .block_listwrapper {padding-right: 0px;} */
}
@media only screen and (max-width: 1199px) {
.counseling_banner .service_banner_con {padding-top: 56%;background-size: 100%;}
.counseling_block1 .left_image_and_text {padding-top: 72% !important;}
.counseling_block2 .textwrapper {margin-top: 50px;}
}

@media only screen and (max-width: 480px) {
    .counseling_block2 .textwrapper {
        margin-top: 25px;
    }
}

