.healthrisk_banner .service_banner_con {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/healthrisk_banner_img.webp);background-position: right bottom -50px;}
.healthrisk_block1 .left_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/healthrisk_block1.webp);}
.healthrisk_block1 .blue_text_background {background-size: 50.3% 100%;}
.healthrisk_block2 .image_wrapper {border: none;}
.healthrisk_block2 .block_listwrapper p {margin-bottom: 0;}
.healthrisk_block3 .left_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/healthrisk_block3.webp);}
.healthrisk_block3 .white-title-white-description p span { color: #00d8ff;}
.healthrisk_block3 .white-title-white-description p span a { color: inherit;text-decoration: none;}

.healthrisk_block4  {background: #dff5f9;}
.healthrisk_block5 .left_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/healthrisk_block5.webp);}
.healthrisk_block5 .left_image_and_text .blue_text_background {background-size: 54.3% 100%;}
.healthrisk_block5 .left_image_and_text .text_position {padding-left: 715px;}



@media only screen and (max-width: 1580px) {
.healthrisk_banner .service_banner_con{background-position: right -23% bottom -50px;}
.healthrisk_block3 .left_image_and_text {background-position: left -70% top;}
.healthrisk_block5 .left_image_and_text .text_position {padding-left: 50%;}
}
@media only screen and (max-width: 1299px) {
.healthrisk_banner .service_banner_con {background-position: right -48% bottom -50px;}
.healthrisk_block3 .left_image_and_text {background-position: left -315px top;}
}

@media only screen and (max-width: 1199px) {
.healthrisk_banner .service_banner_con {background-size: 100%;padding-top: 50%;}
.healthrisk_block1 .left_image_and_text {padding-top: 74% !important;}
.healthrisk_block3 .left_image_and_text {padding-top: 66% !important;}
.healthrisk_block5 .left_image_and_text {padding-top: 72% !important;}
.healthrisk_block2 .textwrapper {margin-top: 25px;}
.healthrisk_block4 .textwrapper {margin-top: 25px;}
.healthrisk_block2 .image_wrapper {border: none;width: 96% !important;}
}


