/* -----------------------------Banner section CSS starts--------------------------- */

.spinal_decom_banner .service_banner_con {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/IEIM_spinal_decom_banner_img.webp);
    padding: 67px 0 35px;
    background-position: right bottom -70px;
}




/* -----------------------------Banner section CSS Ends--------------------------- */


/* -----------------------------Banner section Responsive starts--------------------------- */

@media only screen and (max-width: 1799px) {
    .spinal_decom_banner .service_banner_con {background-position: right -96px bottom -70px;}
}

@media only screen and (max-width: 1499px) {
    .spinal_decom_banner .banner_con_text1 {width: 55%;}
    .spinal_decom_banner .service_banner_con {
        background-position: right -96px bottom -55px;
    }
}

@media only screen and (max-width: 1420px) {
    .spinal_decom_banner .service_banner_con {background-position: right -96px bottom -30px;background-size: 845px auto;}
}

@media only screen and (max-width: 1199px) {
    .spinal_decom_banner .service_banner_con {background-size: 100%;padding-top: 46%;}
    .spinal_decom_banner .banner_con_text1 {width: 100%;}

}

@media only screen and (max-width: 480px) {
    .spinal_decom_banner .service_banner_con {padding-top: 49%;}
}

/* -----------------------------Banner section Responsive Ends--------------------------- */


/* -----------------------------Block1 CSS Starts--------------------------- */

.spinal_decom_block1 .left_image_and_text {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/IEIM_spinal_decom_block1_img.webp);
}
.spinal_decom_block1 .left_image_and_text .blue_text_background {    background-size: 48% 100%;
}
.spinal_decom_block1 .left_image_and_text .text_position {    padding-left: 850px;
}

/* -----------------------------Block1 CSS Ends--------------------------- */

/* -----------------------------Block1 Responsive Starts--------------------------- */

/* @media only screen and (max-width: 1799px) {
} */

@media only screen and (max-width: 1580px) {
    .spinal_decom_block1 .left_image_and_text .text_position {
        padding-left: 56%;
    }
}

@media only screen and (max-width: 1199px) {
    .spinal_decom_block1 .left_image_and_text {padding-top: 54% !important;}
}
/* -----------------------------Block1 Responsive Ends--------------------------- */


/* -----------------------------Block2 CSS Starts--------------------------- */

.spinal_decom_block2 {background-color: #dff5f9;}
.spinal_decom_block2 .textwrapper p {line-height: 27px;}
.spinal_decom_block2 .textwrapper p span {font-weight: var(--font-semibold);}
.spinal_decom_block2 .left_text_and_video {display: flex;justify-content: space-between;align-items: center;}
.spinal_decom_block2 .blue-title-black-description {width: 47%;}
.spinal_decom_block2 .right_video_wrapper {width: 48%;}
.spinal_decom_block2 .right_video_section {width: 100%;position: relative;overflow: hidden;padding-top: 56.25%;}
.spinal_decom_block2 .right_video_section iframe {width: 100%;height: 100%;position: absolute;top: 0;left: 0;right: 0;bottom: 0;border: none;}

.spinal_decom_block2 .full_width_heading_para p {margin-bottom: 25px;}

#player .ytp-autohide {cursor: default !important;}

/* -----------------------------Block2 CSS Ends--------------------------- */

/* -----------------------------Block2 Responsive Starts--------------------------- */

@media only screen and (max-width: 1199px) {
    .spinal_decom_block2 .left_text_and_video {flex-direction: column-reverse;}
    .spinal_decom_block2 .textwrapper p:first-child{margin-top: 25px;}
    .spinal_decom_block2 .right_video_wrapper {width: 100%;}

}


/* -----------------------------Block2 Responsive Ends--------------------------- */


/* -----------------------------Block3 CSS Starts--------------------------- */

.spinal_decom_block3 .left_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/IEIM_spinal_decom_block3_img.webp);}

.spinal_decom_block3 .left_image_and_text .text_position {padding-left: 865px;}
.spinal_decom_block3 .left_image_and_text .blue_text_background {background-size: 47.1% 100%;}

/* -----------------------------Block3 CSS Ends--------------------------- */

/* -----------------------------Block3 Responsive Starts--------------------------- */

@media only screen and (max-width: 1580px) {
    .spinal_decom_block3 .left_image_and_text .text_position {padding-left: 57%;}
    .spinal_decom_block3 .left_image_and_text {background-position: left -22% top;}
}

@media only screen and (max-width: 1440px) {
    .spinal_decom_block3 .left_image_and_text {
        background-position: left -35% top;
    }
}

@media only screen and (max-width: 1400px) {
    .spinal_decom_block3 .left_image_and_text {
        background-position: left -10% top;
    }
}

@media only screen and (max-width: 1199px) {
    .spinal_decom_block3 .left_image_and_text {padding-top: 37% !important;}
}

/* -----------------------------Block3 Responsive Ends--------------------------- */


/* -----------------------------Block4 CSS Starts--------------------------- */


.spinal_decom_block4 .sky_text_background {background: #dff5f9;}
.spinal_decom_block4 .flex_wrapper .colun_count2 {column-gap: 20px;}
.spinal_decom_block4 .textwrapper {width: 65%;}


/* -----------------------------Block4 CSS Ends--------------------------- */
/* -----------------------------Block4 Responsive Starts--------------------------- */

@media only screen and (max-width: 1199px) {
    .spinal_decom_block4 .textwrapper {margin-top: 25px;}
}


/* -----------------------------Block4 Responsive Ends--------------------------- */


/* -----------------------------Block5 CSS Starts--------------------------- */

.spinal_decom_block5 .left_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/IEIM_spinal_decom_block5_img.webp);}
.spinal_decom_block5 .left_image_and_text .blue_text_background {background-size: 46% 100%;}
.spinal_decom_block5 .left_image_and_text .text_position {padding-left: 875px;}

/* -----------------------------Block5 CSS Ends--------------------------- */

/* -----------------------------Block5 Responsive Starts--------------------------- */
@media only screen and (max-width: 1580px) {
    .spinal_decom_block5 .left_image_and_text .text_position {
        padding-left: 58%;
    }
}

@media only screen and (max-width: 1199px) {
    .spinal_decom_block5 .left_image_and_text {padding-top: 37% !important;}
}
/* -----------------------------Block5 Responsive Ends--------------------------- */

/* -----------------------------Block6 CSS Starts--------------------------- */

.spinal_decom_block6 .textwrapper {width: 55%;}
.spinal_decom_block6 .image_wrapper {width: 38%;}
.spinal_decom_block6 .block-padding {background: #dff5f9;}

/* -----------------------------Block6 CSS Ends--------------------------- */
/* -----------------------------Block6 Responsive Starts--------------------------- */

.spinal_decom_block6 .textwrapper {margin-top: 25px;}

/* -----------------------------Block6 Responsive Ends--------------------------- */
