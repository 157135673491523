.videogallery_banner .service_banner_con{
background-image: url(https://all-frontend-assets.s3.amazonaws.com/IEIM/imei_videogallery_banner.webp);
background-position: right 100px top -50px;
}
.demo_image_wrapper img{
display: block;
width: 100%;
}

@media only screen and (max-width: 1460px) {
    .videogallery_banner .service_banner_con{
background-position: right -100px top -50px;

    }
  }

  @media only screen and (max-width: 1199px) {
    .videogallery_banner .service_banner_con{
        background-size: 100%;
        padding-top: 68%;
    }
  }
