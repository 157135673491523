.laser_banner .service_banner_con {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/lasertherapy_banner_img.webp);}
.laser_block1 .left_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/lasertherapy_block1_img.webp);}
.laser_block1 .left_image_and_text .blue_text_background {background-size: 48% 100%;}
.laser_block1 .left_image_and_text .text_position {padding-left: 840px;}
.laser_block2 .textwrapper {width: 53%;}
.laser_block2 .image_wrapper {width: 44%;}
.laser_block3 .right_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/lasertherapy_block3_img.webp);}
.laser_block3 .right_image_and_text .sky_text_background {background-size: 60.1% 100%;}
.laser_block3 .right_image_and_text .text_position {padding-right: 640px;}
.laser_block4 .left_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/lasertherapy_block4_img.webp);}



@media only screen and (max-width: 1580px) {
.laser_block1 .left_image_and_text .text_position{padding-left: 55%;}
.laser_block1 .left_image_and_text .blue_text_background {background-size: 48% 100%;}
.laser_block1 .left_image_and_text {background-position: left -22% top;}
.laser_block3 .right_image_and_text {background-position: right -16% top;}
}
@media only screen and (max-width: 1358px) {
.laser_block3 .block_listwrapper p {display: inline-block;}
}
@media only screen and (max-width: 1260px) {
.laser_block1 .left_image_and_text {background-position: left -31% top;}
}
@media only screen and (max-width: 1199px) {
.laser_block1 .left_image_and_text {padding-top: 53% !important;}
.laser_block4 .left_image_and_text {padding-top: 37% !important;}
.laser_banner .service_banner_con {background-size: 107%;padding-top: 44%;}
.laser_block3 .right_image_and_text {background-size: 100%;padding-top: 74%;}
.laser_block3 .block_listwrapper p {display: block;}
.laser_block2 .textwrapper {margin-top: 25px;}
}
@media only screen and (max-width: 737px){
.laser_block3 .block_listwrapper p {display: inline-block;}
}
@media only screen and (max-width: 499px) {
.laser_banner .service_banner_con {background-size: 126%;padding-top: 52%;}
.laser_block3 .block_listwrapper p {display:block;}
}
