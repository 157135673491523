.Enfdtesting_banner .banner_con{background-image:url(https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/Enfdtesting_image1.png);background-position:right 240px bottom;padding:108px 0 88px}
.Enfdtesting_imgwrapper{background:rgb(29,188,231);background:linear-gradient(83deg,rgba(29,188,231,1) 0%,rgba(27,194,57,1) 100%);padding:20px}
.Enfdtesting_subimgwrapper{border: 2px solid #fff;border-radius: 2px;}
.Enfdtesting_imgwrapper img{display:block;max-width:100%;width:100%}
.Enfdtesting_block2 .textwrapper p span{color: #0ab330;}
.Enfdtesting_block2 .textwrapper h2{color: #0a1e72;}
.Enfdtesting_colcontainer{display:flex;justify-content:space-between;align-items: center;}
.Enfdtesting_block2_right{width:52%}
.Enfdtesting_block2_left{width:43%}
.Enfdtesting_block2{background-image:url(https://all-frontend-assets.s3.amazonaws.com/HEALTH-ANDPAIN-CARE/images/service_bg3.png);background-repeat:no-repeat;background-position:right bottom -160px;background-color:#c9eff2;padding:60px 0}

@media only screen and (max-width: 1320px) {
.Enfdtesting_banner .banner_con{background-position:right 90px bottom}
}
@media only screen and (max-width: 1199px) {
.Enfdtesting_banner .banner_con{background-position:center top 30px;padding:0;background-size:80%;padding-top:71%}
.Enfdtesting_banner .banner_con_text1 h1 br{display:none}
.Enfdtesting_banner .banner_con_text1 h1{font-size:42px;line-height:65px}
.Enfdtesting_colcontainer{flex-wrap: wrap;}
.Enfdtesting_block2_right{width:100%}
.Enfdtesting_block2_left{width:100%;margin-bottom:25px}
.Enfdtesting_block2{padding: 30px 0;}
.Enfdtesting_block2{background:#c9eff2}
}
@media only screen and (max-width: 767px) {
.Enfdtesting_banner .banner_con_text1 h1{font-size:32px;line-height:41px}
.Enfdtesting_imgwrapper{padding: 10px;}
}