
.medication_banner .service_banner_con {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/medications_banner_img.webp);background-position: right bottom -40px;}
.medications_block1 .left_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/medications_block1_img.webp);}
.medications_block1 .left_image_and_text .blue_text_background {background-size: 49% 100%;}
.medications_block1 .left_image_and_text .text_position {padding-left: 840px;}

@media only screen and (max-width: 1580px){
.medication_banner .service_banner_con {background-position: right -80% bottom -40px;}
.medications_block1 .left_image_and_text .text_position {padding-left: 55%;}
}
@media only screen and (max-width: 1499px){
.medications_block1 .left_image_and_text {background-position: left -40% top;}

}
@media only screen and (max-width: 1440px){
.medication_banner .service_banner_con {background-position: right -250px bottom -40px;}
}
@media only screen and (max-width: 1250px){
.medications_block1 .left_image_and_text {background-position: left -330px top;}
}
@media only screen and (max-width: 1199px){
.medication_banner .service_banner_con {background-size: 140%;padding-top: 39%;}
.medications_block1 .left_image_and_text {padding-top: 73% !important;}

}
