/* -------------------Banner CSS Starts------------------ */

.pain_management_banner .service_banner_con {
    background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/IEIM_pain_management_banner_img.webp);
    background-position: right -40px bottom;
}


/* -------------------Banner CSS Ends------------------ */
/* -------------------Banner Responsive Starts------------------ */

@media only screen and (max-width: 1199px) {
.pain_management_banner .service_banner_con {padding-top: 55%;background-size: 165% auto;background-position: right 59% top !important;}
}

@media only screen and (max-width:499px) {
    .pain_management_banner .service_banner_con {
        padding-top: 58%;
    }
}

/* -------------------Banner Responsive Ends------------------ */

/* -------------------Block1 CSS Starts------------------ */

.pain_management_block1 .left_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/IEIM_pain_management_block1_img.webp);}
.pain_management_block1 .left_image_and_text .blue_text_background {background-size: 46% 100%;}
.pain_management_block1 .left_image_and_text .text_position {padding-left: 870px;}

/* -------------------Block1 CSS Ends------------------ */
/* -------------------Block1 Responsive Starts------------------ */

@media only screen and (max-width: 1580px) {
    .pain_management_block1 .left_image_and_text {background-position: left -190px top;}
    .pain_management_block1 .left_image_and_text .text_position {padding-left: 58%;}
}


@media only screen and (max-width: 1199px) {
.pain_management_block1 .left_image_and_text {padding-top: 54% !important;}
}
/* -------------------Block1 Responsive Ends------------------ */

/* -------------------Block2 CSS Starts------------------ */

.pain_management_block2 .textwrapper {width: 52%;}

/* -------------------Block2 CSS Ends------------------ */

/* -------------------Block2 Responsive Starts------------------ */

@media only screen and (max-width: 1199px) {
    .pain_management_block2 .textwrapper {margin-top: 25px;}

}
/* -------------------Block2 Responsive Ends------------------ */

/* -------------------Block3 CSS Starts------------------ */

.pain_management_block3 .right_image_and_text {background-image: url(https://d37pbainmkhx6o.cloudfront.net/IEIM/assets/IEIM_pain_management_block3_img.webp);}
.pain_management_block3 .right_image_and_text .sky_text_background {background-size: 59.3% 100%;}
.pain_management_block3 .right_image_and_text .text_position {padding-right: 707px;}

/* -------------------Block3 CSS Ends------------------ */

/* -------------------Block3 Responsive Starts------------------ */

@media only screen and (max-width: 1580px) {
    .pain_management_block3 .right_image_and_text .text_position {padding-right: 44%;}
}

@media only screen and (max-width: 1299px) {
    .pain_management_block3 .right_image_and_text {background-position: right -60px top;}
}

@media only screen and (max-width: 1199px) {
    .pain_management_block3 .right_image_and_text {padding-top: 72% !important;}
}

/* -------------------Block3 Responsive Ends------------------ */


